import React from "react";
import Avatar from "../avatar/Avatar.js";
import AboutMenu from "./AboutMenu.js";

export default function About() {
  return <>
    <Avatar page="about" />
    <AboutMenu/>
  </>;
}
