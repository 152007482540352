import React from 'react'
import SkillsMenu from './SkillsMenu'
import Avatar from '../avatar/Avatar'

export default function Skills() {
  return (
    <>
      <Avatar page="skills" />
      <SkillsMenu />
    </>
  )
}
