import React, { Component } from "react";
import ProjectsManu from "./ProjectsMenu";

export default class Projects extends Component {
  render() {
    return (
      <>
        <ProjectsManu />
      </>
    );
  }
}
