const subheadingsData = {
  1: [
    {
      title: "About Me",
      content: (
        <>
          <p>
            On a quest to master JavaScript and React, I'm a coder with a
            passion for storytelling. A 90s pop culture enthusiast, I draw
            inspiration from its timeless charm.
          </p>
        </>
      ),
    },
  ],
  2: [
    {
      title: "Bachelor of Arts, Graphic Design",
      content: (
        <>
          <p>
            Wyższa Szkoła Informatyki Stosowanej i Zarządzania, Warsaw, Poland
          </p>
        </>
      ),
    },
    {
      title: "Completed secondary education (A-Levels equivalent)",
      content: (
        <>
          <p>Maria Skłodowska-Curie I High School, Warsaw, Poland</p>
        </>
      ),
    },
  ],
  3: [
    {
      title: "FULL STACK DEVELOPER 07/2023 - Curren",
      content: (
        <>
          <h2>Tech Training Program, London</h2>
          <p>
            Web Application Design and Development, Back-End Development,
            Machine Learning Algorithms, Deployment and DevOps, Code Management
          </p>
        </>
      ),
    },
    {
      title: "SOUS-CHEF 05/2019 - Curren",
      content: (
        <>
          <h2> Young's Pubs, London</h2>
          <p>
            Assisted head chefs in staff training, including teaching best
            practices, kitchen operations and menu training. Helped staff adhere
            to tough restaurant requirements through effective discipline and
            motivation.
          </p>
        </>
      ),
    },
    {
      title: "SALES REPRESENTATIVE 08/2016 - 05/2017",
      content: (
        <>
          <h2>Multimedia, Poland </h2>
          <p>
            Boosted client retention, establishing sales goals and securing
            targeted rates. Grew customer base by acquiring new customers and
            identifying needs to deliver relevant products. Shared comprehensive
            brand and product knowledge to maximise sales
          </p>
        </>
      ),
    },
  ],
};

export default subheadingsData;
