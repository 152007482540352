import pOne from "../assets/projects img/cv-application.jpg";
import pTwo from "../assets/projects img/chat-app.png";
import pThree from "../assets/projects img/ml-app.png";
import pFour from "../assets/projects img/sass-web-app.jpg";
import pFive from "../assets/projects img/weather-app.jpg";
import pSix from "../assets/projects img/react-food-app.jpg";

const projects = {
  1: {
    title: "CV-APPLICATION",
    image: pOne,
    description: (
      <>
        <p>
          Dynamic web application using React, JavaScript, and Three.js,
          showcasing a seamless integration of immersive 3D graphics.
        </p>
      </>
    ),
    github: "https://github.com/Garusxx/cv-application-master",
    demo: "https://react-cv-app-31a43.web.app",
  },
  2: {
    title: "CHAT-APP",
    image: pTwo,
    description: (
      <>
        <p>
          <div class="mt-1 text-xs md:text-sm text-white">
            Chat app with MERN stack: fast, intuitive, modern. Real-time
            chatting, file sharing, seamless connectivity. Join the conversation
            now!
          </div>
        </p>
      </>
    ),
    github: "https://github.com/Garusxx/chat-gpt-app/tree/master",
    demo: "https://chat-app-prod-4dhs.onrender.com/",
  },
  3: {
    title: "ML-APP",
    image: pThree,
    description: (
      <>
        <p>
          <div class="mt-1 text-xs md:text-sm text-white">
            This machine learning app predicts house prices based on their
            distance from the city center using TensorFlow. It's an ideal tool
            for understanding real estate market trends through data-driven
            insights.
          </div>
        </p>
      </>
    ),
    github: "https://github.com/Garusxx/ML--housing-problem",
    demo: "https://ml-housing-problem.onrender.com/",
  },
  4: {
    title: "SASS-WEB-APP",
    image: pFour,
    description: (
      <>
        <p>
          <div class="mt-1 text-xs md:text-sm text-white">
            Simple web page utilizing Sass for styling.
          </div>
        </p>
      </>
    ),
    github: "https://github.com/Garusxx/sass-web-app",
    demo: "https://github.com/Garusxx/sass-web-app",
  },
  5: {
    title: "WEATHER-APP",
    image: pFive,
    description: (
      <>
        <p>
          <div class="mt-1 text-xs md:text-sm text-white">
            <div class="mt-1 text-xs md:text-sm text-white">
              Simple weather application using an API to display current weather
              conditions and forecasts for various locations.
            </div>
          </div>
        </p>
      </>
    ),
    github: "https://github.com/Garusxx/weather-app",
    demo: "https://github.com/Garusxx/weather-app",
  },
  6: {
    title: "REACT-FOOD-APP",
    image: pSix,
    description: (
      <>
        <p>
          <div class="mt-1 text-xs md:text-sm text-white">
            <div class="mt-1 text-xs md:text-sm text-white">
              Simple weather application using an API to display current weather
              conditions and forecasts for various locations.
            </div>
          </div>
        </p>
      </>
    ),
    github: "https://github.com/Garusxx/react-food-app",
    demo: "https://github.com/Garusxx/react-food-app",
  },
};

export default projects;
